<template>
  <null-page text="您还没有内容，快来创建吧！" :nullType="'nullData'">
    <vh-button size="medium" type="primary" round>主要按钮</vh-button>
    <vh-button size="medium" type="default" round plain>次要按钮</vh-button>
  </null-page>
</template>

<script>
  import NullPage from '@/components/NullPage';
  export default {
    components: {
      NullPage
    }
  };
</script>
