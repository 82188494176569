<template>
  <Fragment>
    <!-- NOTE: 空数据情况: 开发人员根据业务调整 -->
    <template v-if="isNoData">
      <null-page text="您还没有内容，快来创建吧！" :nullType="'nullData'">
        <vh-button size="medium" type="primary" round>主要按钮</vh-button>
        <vh-button size="medium" type="default" round plain>次要按钮</vh-button>
      </null-page>
    </template>

    <!-- 非空数据情况 -->
    <PageListBox v-else>
      <!-- NOTE: 搜索栏: 开发人员根据业务调整  -->
      <template slot="search">
        <vh-button size="medium" type="primary" round>主要按钮</vh-button>
        <vh-button size="medium" type="default" round plain>次要按钮</vh-button>
        <vh-button size="medium" type="info" round plain :disabled="multipleSelection.length === 0">
          批量删除
        </vh-button>
        <vh-input
          round
          class="search-input"
          placeholder="请输入查询关键字"
          v-model="keyword"
          clearable
          v-clearEmoij
          size="medium"
          @clear="handleSearch"
          @keyup.enter.native="handleSearch"
        >
          <i class="vh-icon-search vh-input__icon" slot="prefix" @click="handleSearch"></i>
        </vh-input>
      </template>

      <!-- NOTE: 表格数据: 开发人员根据业务调整  -->
      <vh-table
        :data="dataList"
        style="width: 100%"
        @cell-mouse-enter="handleCellMouseEnter"
        @cell-mouse-leave="handleCellMouseLeave"
        @selection-change="handleSelectionChange"
      >
        <template slot="empty">
          <null-page text="暂未搜索到您想要的内容" :nullType="'search'"></null-page>
        </template>
        <!-- 多选 -->
        <vh-table-column type="selection" width="60" fixed="left" />
        <!-- 排序 -->
        <vh-table-column label="排序" width="80">
          <template slot="header">
            排序
            <vh-tooltip effect="dark" placement="top-start" v-tooltipMove>
              <div slot="content">数值越小，排序越靠前，0排在第一位</div>
              <i class="iconfont-v3 saasicon_help_m"></i>
            </vh-tooltip>
          </template>
          <template slot-scope="scope">
            <vh-input
              v-model.number="scope.row.order_num"
              :maxlength="4"
              size="medium"
              oninput="this.value=this.value.replace(/[^\d]/g, '')"
              @keyup.enter.native="updateOrder(scope.row)"
              @blur="updateOrder(scope.row)"
            ></vh-input>
          </template>
        </vh-table-column>
        <!-- 长图片-->
        <vh-table-column prop="img_path2" label="图片标题1" width="140">
          <template slot-scope="scope">
            <div class="image-preview">
              <vh-image style="height: 72px; width: 126px" :src="scope.row.img_path2"></vh-image>
              <div class="cover-mask">
                <vh-radio v-model="selectId" :label="scope.row.id">默认回放</vh-radio>
              </div>
            </div>
          </template>
        </vh-table-column>

        <!-- 方图片-->
        <vh-table-column prop="img_path" label="图片标题2" width="100">
          <template slot-scope="scope">
            <div class="image-preview">
              <vh-image
                style="height: 72px; width: 72px"
                :src="scope.row.img_path"
                :preview-src-list="[scope.row.img_path]"
              ></vh-image>
            </div>
          </template>
        </vh-table-column>

        <!-- 名称 -->
        <vh-table-column prop="name" label="名称" width="120">
          <template slot-scope="scope">
            <vh-tooltip
              :disabled="!isTextOverflow"
              effect="dark"
              placement="top-start"
              :content="scope.row.name"
            >
              <div class="custom-tooltip-content">{{ scope.row.name }}</div>
            </vh-tooltip>
          </template>
        </vh-table-column>
        <!-- 内容 -->
        <vh-table-column prop="description" min-width="160" label="描述内容宽度自适应">
          <template slot-scope="scope">
            <vh-tooltip
              :disabled="!isTextOverflow"
              effect="dark"
              placement="top-start"
              :content="scope.row.description"
            >
              <div class="custom-tooltip-content">{{ scope.row.description }}</div>
            </vh-tooltip>
          </template>
        </vh-table-column>

        <!-- 状态 -->
        <vh-table-column label="状态" width="80">
          <template slot="header">
            状态
            <vh-tooltip effect="dark" placement="top-start" v-tooltipMove>
              <div slot="content">这是状态描述</div>
              <i class="iconfont-v3 saasicon_help_m"></i>
            </vh-tooltip>
          </template>
          <template slot-scope="scope">
            <StatusText :type="scope.row.statusDotType">
              {{ scope.row.status | statusFilter }}
            </StatusText>
          </template>
        </vh-table-column>
        <!-- 数量 -->
        <vh-table-column prop="num" label="数量" width="60"></vh-table-column>
        <!-- 创建时间 -->
        <vh-table-column prop="create_time" label="创建时间" width="160">
          <template slot-scope="scope">
            <span>{{ scope.row.create_time | dateFormat('YYYY-MM-DD HH:mm') }}</span>
          </template>
        </vh-table-column>
        <!-- 操作 -->
        <vh-table-column label="操作" width="200" fixed="right">
          <template slot-scope="scope">
            <vh-button type="text">复制</vh-button>
            <vh-button type="text">编辑</vh-button>
            <vh-button type="text" :disabled="true">删除</vh-button>
            <vh-dropdown @command="handleCommand">
              <vh-button type="text" style="margin-left: 12px">更多</vh-button>
              <vh-dropdown-menu style="width: 160px" slot="dropdown">
                <vh-dropdown-item :command="{ cmd: 'menu1', row: scope.row }">
                  菜单1
                </vh-dropdown-item>
                <vh-dropdown-item :command="{ cmd: 'menu2', row: scope.row }">
                  菜单2
                </vh-dropdown-item>
                <vh-dropdown-item :command="{ cmd: 'menu3', row: scope.row }">
                  菜单3
                </vh-dropdown-item>
              </vh-dropdown-menu>
            </vh-dropdown>
          </template>
        </vh-table-column>
      </vh-table>

      <!-- NOTE: 分页: 开发人员根据业务调整  -->
      <template slot="footer">
        <SPagination
          :total="total || 0"
          :page-size="pagination.limit"
          :currentPage="pagination.pageNo"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        ></SPagination>
      </template>
    </PageListBox>
  </Fragment>
</template>

<script>
  import PageListBox from '@/components/PageListBox';
  import NullPage from '@/components/NullPage';
  import tableCellTooltip from '@/components/TableList/mixins/tableCellTooltip';
  import dataJson from './data.json';
  import { Fragment } from 'vue-frag';
  import StatusText, { StatusDotEnum } from '@/components/StatusText/index.vue';

  const _StatusMap = {
    0: '未推送',
    1: '推送中',
    2: '已推送'
  };

  export default {
    name: 'TabContent2vue',
    components: {
      PageListBox,
      NullPage,
      Fragment,
      StatusText
    },
    mixins: [tableCellTooltip],
    data() {
      return {
        isNoData: false,

        keyword: '',
        multipleSelection: [],
        dataList: [],
        total: 0, // 总数量
        isEmpty: false,
        selectId: '',

        // 分页参数
        pagination: {
          limit: 10,
          pageNo: 1
        }
      };
    },
    created() {
      this.isNoData = this.$route.query.data == 0;
      this.fetchData();
    },
    filters: {
      statusFilter(val) {
        return _StatusMap[val];
      }
    },
    methods: {
      getStatusDotType(val) {
        if (val === 1) return StatusDotEnum.DOING;
        if (val == 2) return StatusDotEnum.SUCCESS;
        return StatusDotEnum.PENDING;
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      handleSearch() {
        this.pagination.pageNo = 1;
        this.fetchData();
      },
      // 翻页事件
      handleCurrentChange(current) {
        this.pagination.pageNo = current; //当前页码
        this.fetchData();
      },
      // 每页条数改变时触发
      handleSizeChange(limit) {
        this.pagination.limit = limit;
        this.pagination.pageNo = 1;
        this.fetchData();
      },
      // 模拟请求数据
      fetchData: async function () {
        // this.loading = true;
        this.keyword = this.keyword.trim();
        const dataList = dataJson.list.filter(item => {
          return item.name.indexOf(this.keyword) > -1;
        });
        // // this.loading = false;
        // if (err || res?.code != 200) {
        //   this.$message.error(err?.msg || res?.msg || '请求数据失败');
        //   return;
        // }
        // this.isInited = true;
        this.total = dataList.length || 0;
        this.isEmpty = !this.total && this.keyword.length === 0;
        const pos = (this.pagination.pageNo - 1) * this.pagination.limit;
        const filterDataList = dataList.slice(pos, pos + this.pagination.limit);
        filterDataList.forEach(item => {
          item.statusDotType = this.getStatusDotType(item.status);
        });
        this.dataList = filterDataList;
      },
      handleCommand({ cmd, row }) {
        this.$vhMessage.info(`你点击了菜单${cmd}`);
        console.log(dataJson);
      }
    }
  };
</script>
