<template>
  <div class="page-create-box">
    <div class="create-pane">
      <div class="create-pane__left">
        <vh-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          label-width="80px"
          class="std-form"
        >
          <!-- 抽奖动效 -->
          <vh-form-item label="抽奖动效" required>
            <div class="content-body content-body--lay">
              <div
                class="content-body__card for-upload"
                :class="[{ 'is-active': ruleForm.lay == 'custom' }]"
                @click="handleChangeCard('custom')"
              >
                <div class="card-content content-upload">
                  <vh-upload
                    style="width: 86px; height: 86px; border: none"
                    action="https://jsonplaceholder.typicode.com/posts/"
                  >
                    <i class="iconfont-v3 saasicon_shangchuan"></i>
                  </vh-upload>
                </div>
              </div>
              <div
                class="content-body__card"
                :class="[{ 'is-active': ruleForm.lay == '1' }]"
                @click="handleChangeCard('1')"
              >
                <div class="card-content card-content--1"></div>
              </div>
              <div
                class="content-body__card"
                :class="[{ 'is-active': ruleForm.lay == '2' }]"
                @click="handleChangeCard('2')"
              >
                <div class="card-content card-content--2"></div>
              </div>
              <div
                class="content-body__card"
                :class="[{ 'is-active': ruleForm.lay == '3' }]"
                @click="handleChangeCard('3')"
              >
                <div class="card-content card-content--3"></div>
              </div>
            </div>
            <div class="content-footer">
              <span class="text-regular-9">
                建议尺寸：240*240px，小于2MB，支持jpg、gif、png、bmp
              </span>
            </div>
          </vh-form-item>

          <!-- 抽奖提示 -->
          <vh-form-item label="抽奖标题" prop="name" required>
            <vh-input
              v-model="ruleForm.name"
              placeholder="请输入抽奖标题"
              maxlength="20"
              show-word-limit
            ></vh-input>
          </vh-form-item>

          <!-- 抽奖提示 -->
          <vh-form-item label="抽奖提示" prop="message" required>
            <vh-input
              v-model="ruleForm.message"
              placeholder="抽奖进行中"
              maxlength="20"
              show-word-limit
            ></vh-input>
          </vh-form-item>

          <!-- 开奖时间 -->
          <vh-form-item label="开奖时间" prop="way">
            <vh-radio-group v-model="ruleForm.way">
              <vh-radio label="1">手动开奖</vh-radio>
              <vh-radio label="2">
                推送抽奖后
                <vh-input
                  v-model="ruleForm.wayCustom"
                  type="number"
                  :max="300"
                  autocomplete="off"
                  placeholder="3-300"
                  @focus="ruleForm.way = '2'"
                  class="radio-input"
                  style="margin-left: 2px"
                >
                  <template slot="suffix">分钟</template>
                </vh-input>
                自动开奖
              </vh-radio>
            </vh-radio-group>
          </vh-form-item>

          <!-- 展示效果 -->
          <vh-form-item label="展示效果" prop="pos" class="auto-height">
            <div class="content-title">设置抽奖在手机观看端展示位置</div>
            <div class="content-body" style="margin-top: 12px">
              <vh-radio-group v-model="ruleForm.pos">
                <vh-radio label="full">全屏展示</vh-radio>
                <vh-radio label="half">半屏展示</vh-radio>
              </vh-radio-group>
            </div>
          </vh-form-item>

          <!-- 领奖方式 -->
          <vh-form-item label="领奖方式" prop="kind" class="auto-height">
            <vh-radio-group v-model="ruleForm.kind">
              <vh-radio label="1" class="lh-20">寄送奖品</vh-radio>
              <vh-radio label="2" class="lh-20">私信兑奖</vh-radio>
              <vh-radio label="3" class="lh-20">无效兑奖</vh-radio>
            </vh-radio-group>
          </vh-form-item>

          <!-- 手机号 -->
          <vh-form-item label="手机号" prop="phone">
            <div class="content-title">
              <vh-input
                v-model="ruleForm.phone"
                placeholder="请输入手机号"
                maxlength="20"
                show-word-limit
              ></vh-input>
            </div>
            <div class="content-body at-right">
              <span class="text-regular-8">手机号校验</span>
              <vh-switch
                v-model="ruleForm.checkPhone"
                active-color="#FB3A32"
                inactive-color="#CECECE"
                class="ml-8"
              ></vh-switch>
              <span class="text-regular-8 ml-16">必填</span>
              <vh-switch
                v-model="ruleForm.phoneRequired"
                active-color="#FB3A32"
                inactive-color="#CECECE"
                class="ml-8"
              ></vh-switch>
            </div>
          </vh-form-item>

          <!-- 地址 -->
          <vh-form-item label="地址" prop="address">
            <div class="content-title">
              <vh-input
                v-model="ruleForm.address"
                placeholder="请输入地址"
                maxlength="20"
                show-word-limit
              ></vh-input>
            </div>
            <div class="content-body at-right">
              <span class="text-regular-8">必填</span>
              <vh-switch
                v-model="ruleForm.addressRequired"
                active-color="#FB3A32"
                inactive-color="#CECECE"
                class="ml-8"
              ></vh-switch>
            </div>
          </vh-form-item>

          <!-- 强制领奖 -->
          <vh-form-item label="强制领奖" prop="force" class="auto-height">
            <div class="content-title" style="display: flex">
              <vh-switch
                v-model="ruleForm.force"
                active-color="#FB3A32"
                inactive-color="#CECECE"
              ></vh-switch>
              <span class="switch-label" style="line-height: 1.6">
                {{
                  ruleForm.force
                    ? '已开启，中奖用户在未完成领奖时将无法关闭中奖页面及提交领奖页，超长换行超长换行超长换行超长换行超长换行'
                    : '开启后，中奖用户在未完成领奖时将无法关闭中奖页面及提交领奖页，超长换行超长换行超长换行超长换行超长换行'
                }}
              </span>
            </div>
          </vh-form-item>

          <!-- 隐私协议 Privacy Agreement -->
          <vh-form-item label="隐私协议" prop="pa" class="auto-height">
            <div class="content-title" style="display: flex">
              <vh-radio-group v-model="ruleForm.pa">
                <vh-radio label="1" class="lh-20">展示系统自带协议</vh-radio>
                <vh-radio label="2" class="lh-20">自定义协议</vh-radio>
              </vh-radio-group>
              <vh-button type="text" class="ml-16" @click="hanldeGoSetting">去设置</vh-button>
            </div>
            <div class="content-body content-body--pa-preview text-regular-8">
              预览效果：我们根据保护您填写的所有信息，我们根据保护您填写的所有信息我们根据保护您填写的所有信息我们根据保护您填写的所有信息我们根据保护您填写的所有信息我们根据保护您填写的所有信息我们根据保护您填写的所有信息。
            </div>
          </vh-form-item>

          <vh-form-item>
            <vh-button round type="primary" @click="submitForm('ruleForm')">保存</vh-button>
          </vh-form-item>
        </vh-form>
      </div>
      <div class="create-pane__right">
        <SimulatorPreview :onlyMobile="true">
          <!-- TODO：跟随左侧内容变化 -->
          <div class="mobile-preview" slot="mobile">这里跟需要随左侧操作变化内容</div>
        </SimulatorPreview>
      </div>
    </div>
  </div>
</template>

<script>
  import SimulatorPreview from '@/components/SimulatorPreview/index.vue';

  export default {
    name: 'TabContent4',
    components: {
      SimulatorPreview
    },
    data() {
      return {
        ruleForm: {
          lay: '1',
          name: '', // 直播标题
          message: '抽奖进行中...', // 抽奖提示
          way: '1',
          wayCustom: undefined,
          pos: 'full',
          kind: '1',
          phone: '请输入手机号',
          checkPhone: true,
          phoneRequired: true,
          address: '',
          addressRequired: true,
          force: true,
          pa: '1' // 隐私协议
        },
        rules: {
          name: [
            { required: true, message: '请输入抽奖标题', trigger: 'blur' },
            { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
          ],
          message: [
            { required: true, message: '请输入抽奖提示', trigger: 'blur' },
            { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
          ]
        }
      };
    },
    methods: {
      handleChangeCard(val) {
        if (val === 'custom') {
          //  TODO: 其它处理
        } else {
          this.ruleForm.lay = val;
        }
      },
      hanldeGoSetting() {
        this.$vhAlert('这里弹窗隐私协议弹窗');
      },
      submitForm(formName) {
        this.$refs[formName].validate(valid => {
          if (valid) {
            alert('submit!');
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    }
  };
</script>
<style lang="less" scoped>
  .create-pane {
    &__left {
      // 抽奖动效卡片
      .content-body.content-body--lay {
        .content-body__card {
          width: 88px;
          height: 88px;
        }
        .card-content {
          position: relative;
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-size: contain;

          &--1 {
            background-image: url('https://t-alistatic01.e.vhall.com/saas-v3-web-test01/static/img/prize01.c56c36ba.png');
          }

          &--2 {
            background-image: url('https://t-alistatic01.e.vhall.com/saas-v3-web-test01/static/img/prize02.b18ed754.png');
          }

          &--3 {
            background-image: url('https://t-alistatic01.e.vhall.com/saas-v3-web-test01/static/img/prize03.4fd09487.png');
          }
        }
      }

      // 隐私协议效果预览
      .content-body.content-body--pa-preview {
        margin-top: 12px !important;
        background-color: #f5f5f5;
        padding: 7px 12px;
        border-radius: 4px;
        color: #595959;
      }
    }
    &__right {
      width: 380px;
      padding-left: 24px;

      .mobile-preview {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
      }
    }
  }
</style>
